import { Component, EventEmitter, Input,Output } from '@angular/core';
import { Cart, Outlet, User } from '@models/index';
import { CartItemComponent } from "../../cart-item/cart-item.component";
import { formatCurrency } from '../../../utils/shared/shared';
import { AppDialogComponent } from "../app-dialog/app-dialog.component";
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TextareaFieldComponent } from "../textarea-field/textarea-field.component";
import { DeliveryOptionComponent } from "../../delivery-option/delivery-option.component";

@Component({
  selector: 'app-cart-card',
  standalone: true,
  imports: [CartItemComponent, AppDialogComponent, ReactiveFormsModule, TextareaFieldComponent, DeliveryOptionComponent],
  templateUrl: './cart-card.component.html',
  styleUrl: './cart-card.component.css'
})
export class CartCardComponent {
  @Input() cart: Cart[][] = [];
  @Input() user: User | null = null;
  @Input() outlet: Outlet | undefined = undefined;
  @Input() deliveryLocation: string = '';
  @Input() deliveryFee: number = 0;
  @Input() serviceCharge: number = 0;
  @Input() totalCost = 0;
  @Input() ordersCost = 0;
  @Input() selectedDeliveryMode: number = 0;
  ordersCostString = '';
  @Input() uniqueId = '';
  @Input() cardTitle = '';
  @Input() deliveryInstructionsForm: FormControl = new FormControl();
  @Input() vendorInstructionsForm: FormControl = new FormControl();
  @Input() enableDeliveryOptions: boolean = true;

  @Output() incrementDecrementEvent = new EventEmitter<{ cartItem: Cart, increment: boolean }>();
  @Output() removeFromCart = new EventEmitter<{ cartItem: Cart, index: number }>();
  @Output() deliveryModeChanged = new EventEmitter<number>();
  @Output() clearCart = new EventEmitter<void>();
  @Output() newOrder = new EventEmitter<void>();
  @Output() checkout = new EventEmitter<void>();
  @Output() repeatOrderEvent = new EventEmitter<void>();
  @Output() quantityChangedEmitter = new EventEmitter<{ currentCart: number, productId: number, quantity: number }>();
  @Output() changeDeliveryAddressEvent = new EventEmitter<void>();
  @Output() deleteOrderEvent = new EventEmitter<number>();
  @Output() vendorInstructionsChanged = new EventEmitter<string>();
  @Output() deliveryInstructionsChanged = new EventEmitter<string>();
  deliveryMode : number = 0;
  deliveryInstructionDialog: boolean = false;
  vendorInstructionsDialog: boolean = false;
  private savedVendorInstructions: string = '';
  private savedDeliveryInstructions: string = '';


  checkoutEvent() {
    this.checkout.emit();
  }

  toggleDeliveryInstructionsDialog() {
    if(!this.deliveryInstructionDialog){
      this.deliveryInstructionsForm.setValue(this.savedDeliveryInstructions || '');
      this.deliveryInstructionDialog = true;
    }
    else{
      this.deliveryInstructionDialog = false;
    }
  }

  toggleVendorInstructionsDialog() {
    if (!this.vendorInstructionsDialog) {
      this.vendorInstructionsForm.setValue(this.savedVendorInstructions || '');
      this.vendorInstructionsDialog = true;
    } else {
      this.vendorInstructionsDialog = false;
    }
  }

  onDialogCancel() {
    this.vendorInstructionsForm.setValue(this.savedVendorInstructions || '');
    this.vendorInstructionsDialog = false;
  }

  onDeliveryDialogCancel() {
    this.deliveryInstructionsForm.setValue(this.savedDeliveryInstructions || '');
    this.deliveryInstructionDialog = false;
  }


  saveVendorInstructions() {
    this.savedVendorInstructions = this.vendorInstructionsForm.value;
    this.vendorInstructionsDialog = false;
    this.vendorInstructionsChanged.emit(this.savedVendorInstructions);
  }

  saveDeliveryInstructions() {
    this.savedDeliveryInstructions = this.deliveryInstructionsForm.value;
    this.deliveryInstructionDialog = false;
    this.deliveryInstructionsChanged.emit(this.savedDeliveryInstructions);
  }

  formatCurrency = formatCurrency

  newOrderEvent() {
    this.newOrder.emit();
  }

  clearCartEvent() {
    this.clearCart.emit();
  }

  deleteOrder(index: number) {
    this.deleteOrderEvent.emit(index);
  }

  repeatOrder() {
    this.repeatOrderEvent.emit();
  }

  changeDeliveryAddress($event: void) {
    this.changeDeliveryAddressEvent.emit();
  }

  deliveryModeChangedEvent(selectedDeliveryMode: number) {
    this.deliveryMode = selectedDeliveryMode;
    // if (selectedDeliveryMode === 1 && (!this.user?.address || !this.user?.phone || this.user?.address === '' || this.user?.phone === '')) {
    //   this.changeDeliveryAddressEvent.emit();
    //   return;
    // }
    this.selectedDeliveryMode = selectedDeliveryMode;
    this.deliveryModeChanged.emit(selectedDeliveryMode);
  }

  incrementDecrement(cartItem: Cart, increment: boolean) {
    this.incrementDecrementEvent.emit({ cartItem, increment });
  }

  removeFromCartEvent(cartItem: Cart, index: number) {
    this.removeFromCart.emit({ cartItem, index });
  }

  quantityChanged(currentCart: number, productId: number, quantity: number) {
    this.quantityChangedEmitter.emit({ currentCart, productId, quantity })
  }
}
